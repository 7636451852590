<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_one-click-info'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title" v-if="activeInfo">
          {{ activeInfo.name }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-edit-done" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm @submit="submitForm" v-slot="{ errors }" class="form edit-form">
      <ul class="clebex-item-section pill" v-if="activeInfo">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="infoName"
                >{{ displayLabelName("one-click", "info-edit", "name") }}
              </label>
              <Field
                name="name"
                as="input"
                rules="required"
                type="text"
                id="infoName"
                v-model="activeInfo.name"
              />
              <span class="error-message" v-if="errors.name">
                {{ errors.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="infoUrl"
                >{{ displayLabelName("one-click", "info-edit", "url") }}
              </label>
              <Field
                name="url"
                as="input"
                rules="required"
                type="text"
                id="infoUrl"
                v-model="activeInfo.url"
              />
              <span class="error-message" v-if="errors.number">
                {{ errors.number }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="infoDescription"
                >{{ displayLabelName("one-click", "info-edit", "description") }}
              </label>
              <Field
                name="description"
                as="input"
                type="text"
                id="infoDescription"
                v-model="activeInfo.description"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "OneClickInfoEdit",
  data() {
    return {
      activeInfo: null
    };
  },
  computed: {
    ...mapState("oneClick", ["info"])
  },
  created() {
    if (
      this.$route &&
      this.$route.params.infoId &&
      this.info &&
      this.info.length
    ) {
      this.activeInfo = this.info.find(
        el => el.id == this.$route.params.infoId
      );
    }
  },
  methods: {
    ...mapActions("oneClick", ["editInfo"]),
    submitForm(values) {
      if (values) {
        this.editInfo(this.activeInfo).then(() => {
          this.$router.push({
            name: "r_one-click-info"
          });
        });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  watch: {
    $route: {
      handler(val) {
        // do only while on this page
        if (val.name === "r_one-click-info-edit") {
          this.activeInfo = this.info.find(
            el => el.id == this.$route.params.infoId
          );
        }
      }
    },
    info: {
      handler() {
        this.activeInfo = this.info.find(
          el => el.id == this.$route.params.infoId
        );
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
